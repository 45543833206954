// src/pages/AuthPage.js
import React, { useEffect, useState } from "react";
import {
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword as firebaseSignIn,
  getRedirectResult,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../store/reducers/userSlice";
import axios from "axios";

import { MdEmail } from "react-icons/md";
import { FaLock } from "react-icons/fa";

// Initialize Google Auth Provider
/* const provider = new GoogleAuthProvider(); */

const AuthPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [persistSession, setPersistSession] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const auth = getAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Handle getRedirectResult
    getRedirectResult(auth)
      .then((result) => {
        if (result) {
          const user = result.user;
          console.log("User signed in with redirect:", user);

          // Dispatch the user to Redux
          dispatch(
            login({
              uid: user.uid,
              email: user.email,
              displayName: user.displayName,
              photoURL: user.photoURL,
            })
          );

          navigate("/"); // Redirect to home page
        } else {
          console.log("No redirect result");
        }
      })
      .catch((error) => {
        console.error("Error during getRedirectResult:", error);
      });

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User is signed in:", user);

        // Dispatch the user to Redux
        dispatch(
          login({
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
          })
        );

        navigate("/"); // Redirect if user is signed in
      } else {
        console.log("User is signed out");
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth, navigate, dispatch]);

  const validatePassword = (password) => {
    const passwordRegex = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{6,20})/;
    return passwordRegex.test(password);
  };

  const createUserInMongoDB = async (uid, email) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/users/`, {
        uid,
        email,
      });
      console.log("User successfully created in MongoDB");
    } catch {
      setErrorMessage("Failed to create user in MongoDB. Please try again.");
    }
  };

  // Handle Sign Up / Sign In with Email and Password
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(null);

    if (!validatePassword(password)) {
      setErrorMessage(
        "Password must be between 6-20 characters, contain at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      return;
    }

    const persistenceType = persistSession
      ? browserSessionPersistence
      : browserLocalPersistence;

    try {
      // set persistence based on user choice
      await setPersistence(auth, persistenceType);

      // Try to sign in
      const userCredential = await firebaseSignIn(auth, email, password);
      const user = userCredential.user;
      console.log("User successfully signed in");

      // Dispatch the user to Redux
      dispatch(
        login({
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
        })
      );

      navigate("/"); // Redirect after successful sign-in
    } catch (error) {
      console.error("Error during sign-in:", error);

      if (error.code === "auth/user-not-found") {
        try {
          // User not found, create new user
          const userCredential = await createUserWithEmailAndPassword(
            auth,
            email,
            password
          );
          const user = userCredential.user;
          await createUserInMongoDB(user.uid, user.email);
          console.log("User successfully created in both Firebase and MongoDB");

          // Send email verification
          await sendEmailVerification(user);
          console.log("Email verification sent");

          // Dispatch the user to Redux
          dispatch(
            login({
              uid: user.uid,
              email: user.email,
              displayName: user.displayName,
              photoURL: user.photoURL,
            })
          );

          navigate("/"); // Redirect after successful sign-up
        } catch (signupError) {
          console.error("Error during sign-up:", signupError);
          setErrorMessage("Failed to create user. Please try again.");
        }
      } else if (error.code === "auth/wrong-password") {
        setErrorMessage("Incorrect password. Please try again.");
      } else if (error.code === "auth/invalid-credential") {
        setErrorMessage("Invalid credentials. Please try again.");
      } else {
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  // Handle Password Reset
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setErrorMessage(null);

    if (!email) {
      setErrorMessage("Please enter your email address.");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setErrorMessage("Password reset email sent. Please check your inbox.");
      setResetPassword(false);
    } catch (error) {
      console.error("Error sending password reset email:", error);
      if (error.code === "auth/user-not-found") {
        setErrorMessage("User not found. Please check the email address.");
      } else {
        setErrorMessage("An error occurred. Please try again.");
      }
    }
  };

  // Handle Sign In with Google
  /* const handleGoogleSignIn = () => {
    console.log("Google Sign-In");
    signInWithRedirect(auth, provider)
      .then(() => {
        console.log("Redirecting to Google Sign-In");
      })
      .catch((error) => {
        console.error("Error during signInWithRedirect:", error);
      });
  }; */

  return (
    <div className="max-w-md p-8 mx-auto my-12 font-semibold bg-white border shadow-2xl rounded-3xl">
      <h2 className="mb-6 text-5xl font-bold text-center underline text-meek-dark decoration-4 decoration-meek dark:decoration-blue-600">
        Discipleship
      </h2>
      <img
        src={
          isHovered
            ? "https://firebasestorage.googleapis.com/v0/b/christlike-attributes-c0fd3.appspot.com/o/discipleship-official%2Fdisciplesheep-happy-label.png?alt=media&token=cd8414d1-c2bc-4e2e-a2e8-ea8916a7e33c"
            : "https://firebasestorage.googleapis.com/v0/b/christlike-attributes-c0fd3.appspot.com/o/discipleship-official%2Fdisciplesheep-nobg.png?alt=media&token=dedeaedd-b5b9-495e-9802-7dffd423c9d1"
        }
        className="h-auto mx-auto transition duration-300 ease-in-out w-36"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        alt="Discipleship Logo"
      />
      {resetPassword ? (
        // Password Reset Form
        <form onSubmit={handlePasswordReset}>
          <div className="mb-6">
            <label htmlFor="email" className="block mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-4 py-2 border rounded-md focus:ring focus:ring-haze"
            />
          </div>

          {errorMessage && (
            <p className="mb-4 text-sm text-red-500">{errorMessage}</p>
          )}

          <button
            type="submit"
            className="w-full px-4 py-2 mb-4 transition duration-150 border rounded-lg hover:text-white text-haze hover:border-slate-400 border-slate-200 bg-outrageous-500 hover:shadow-lg"
          >
            Send Password Reset Email
          </button>

          <button
            type="button"
            onClick={() => setResetPassword(false)}
            className="w-full px-4 py-2 transition duration-150 bg-gray-500 border rounded-lg hover:text-white text-haze hover:border-slate-400 border-slate-200 hover:shadow-lg"
          >
            Back to Login
          </button>
        </form>
      ) : (
        // Sign In / Sign Up Form
        <form onSubmit={handleSubmit}>
          <div className="my-6">
            <div className="relative flex items-center w-full border rounded-3xl">
              <MdEmail className="absolute w-5 h-5 left-3 text-meek-dark" />
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                autoComplete="email"
                className="w-full py-2 pl-10 bg-meek-light rounded-3xl focus-within:ring focus-within:ring-white"
              />
            </div>
          </div>

          <div className="mb-6">
            <div className="relative flex items-center w-full border rounded-3xl">
              <FaLock className="absolute left-3.5 text-meek-dark w-4 h-4" />
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (errorMessage) {
                    setErrorMessage(null);
                  }
                }}
                required
                autoComplete="current-password"
                className="w-full py-2 pl-10 bg-meek-light rounded-3xl focus-within:ring focus-within:ring-white"
              />
            </div>
            <button
              type="button"
              onClick={() => setResetPassword(true)}
              className="mt-2 text-sm text-gray-500 hover:underline"
            >
              Forgot Password?
            </button>
          </div>

          {errorMessage && (
            <p className="mb-4 text-sm text-red-500">{errorMessage}</p>
          )}

          <div className="mb-4">
            <label htmlFor="persistSession" className="flex items-center">
              <input
                type="checkbox"
                id="persistSession"
                checked={persistSession}
                onChange={() => setPersistSession(!persistSession)}
                className="mr-2"
              />
              <span className="text-meek-dark">Remember me</span>
            </label>
          </div>

          <button
            type="submit"
            className="w-full px-4 py-2 text-white transition duration-150 border border-white rounded-lg bg-meek-dark hover:text-meek-dark text-haze hover:bg-meek-light hover:border-gray-400 hover:shadow-lg"
          >
            Sign In / Sign Up
          </button>
        </form>
      )}
      {/* <div className="relative flex items-center py-5">
        <div className="flex-grow border-t"></div>
        <span className="mx-4">Or</span>
        <div className="flex-grow border-t"></div>
      </div> */}
      {/* <div className="flex items-center justify-center py-2 mx-auto">
        <button
          onClick={handleGoogleSignIn}
          className="flex gap-2 px-4 py-2 transition duration-150 border rounded-lg border-slate-200 dark:border-slate-700 text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 hover:shadow"
        >
          <img
            className="w-6 h-6"
            src="https://www.svgrepo.com/show/475656/google-color.svg"
            loading="lazy"
            alt="google logo"
          />
          <span>Login with Google</span>
        </button>
      </div> */}
      <div className="flex items-center justify-center gap-2 mt-12">
        <p className="text-xs text-gray-500 dark:text-gray-400">
          By continuing you agree to our <a href="/tos" className="font-bold">Terms</a> & {" "}
          <a href="/privacy-policy" className="font-bold">Privacy Policy</a>
        </p>
      </div>
    </div>
  );
};

export default AuthPage;
