import React from "react";
/* import BudgetProgress from "../components/BudgetProgress"; */
import Navbar from "../components/constants/Navbar";
import AppBar from "../components/constants/AppBar";
import Footer from "../components/constants/Footer";
import { FaQuoteRight } from "react-icons/fa";

const About = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar />
      <AppBar />

      <section className="py-10 sm:pt-16 2xl:py-16">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="grid items-center grid-cols-1 gap-8 md:grid-cols-2">
            <div data-aos="fade-right">
              <h2 className="text-4xl font-bold leading-tight text-gray-800 sm:text-5xl lg:text-6xl">
                Hello, I'm
              </h2>
              <h2 className="mt-2 text-4xl font-bold leading-tight text-blue-600 sm:text-5xl lg:text-6xl">
                Michael DiPaolo
              </h2>
              <p className="max-w-lg mt-6 text-lg leading-relaxed text-gray-700 md:mt-8">
                I struggled for long time to know how I could best serve God.
                Every time I prayed to know this, He always answered me the same
                way, with the words from Elder Jeffrey R. Holland's talk,{" "}
                <a href="https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://www.churchofjesuschrist.org/study/ensign/2012/11/sunday-morning-session/the-first-great-commandment%3Flang%3Deng&ved=2ahUKEwiih42a1vqIAxVjGFkFHdjQLE8QFnoECBoQAQ&usg=AOvVaw2Mj_sZaeCZZfkYnfTLudtq">
                  "The First Great Commandment"
                </a>.
              </p>
            </div>
            <div data-aos="fade-left" className="hidden md:block">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/christlike-attributes-c0fd3.appspot.com/o/discipleship-official%2FDeveloper.png?alt=media&token=6410a8e5-8fce-4b90-82c2-701b7f2ee004"
                alt="Michael DiPaolo"
                className="w-full h-auto rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>

        <div className="max-w-5xl px-4 mx-auto mt-12 sm:px-6 lg:px-8">
          <p className="mt-6 text-lg leading-relaxed text-gray-700">
            Elder Holland recounted the story of Peter and Jesus on the shore of
            the Sea of Galilee. Jesus asked Peter three times, “Peter, do you
            love me?” Each time Peter responded, “Yea, Lord; thou knowest I love
            thee.”{" "}
          </p>
        </div>

        <div className="max-w-5xl px-4 mx-auto mt-12 sm:px-6 lg:px-8">
          <blockquote className="p-8 text-xl bg-white rounded-lg shadow-lg">
            <FaQuoteRight className="inline-block w-10 h-10 mb-4 text-4xl text-blue-600" />
            <p className="mt-4 text-gray-700">
              To which Jesus responded, “Then Peter, why are we back on this
              same shore, by these same nets, having this same conversation?
              Wasn't it obvious then and isn't it obvious now that{" "}
              <strong>if I want fish, I can get fish?</strong>"
            </p>
          </blockquote>
        </div>

        <div className="max-w-5xl px-4 mx-auto mt-12 sm:px-6 lg:px-8">
          <p className="mt-6 text-lg leading-relaxed text-gray-700">
            For a long time, I responded to God by saying, "Okay, You don't need
            fish, <em>but I do</em>. I need to provide for my family."
          </p>
          <p className="mt-6 text-lg leading-relaxed text-gray-700">
            It took me a while to recall the next profound lines from Elder
            Holland's talk:
          </p>
        </div>

        <div className="max-w-5xl px-4 mx-auto mt-12 sm:px-6 lg:px-8">
          <blockquote className="p-8 text-xl bg-white rounded-lg shadow-lg">
            <FaQuoteRight className="inline-block w-10 h-10 mb-4 text-4xl text-blue-600" />
            <p className="mt-4 text-gray-700">
              “What I need, Peter, are disciples—and I need them forever. I need
              someone to feed my sheep and save my lambs. I need someone to
              preach my gospel and defend my faith. I need someone who loves me,
              truly, truly loves me, and loves what our Father in Heaven has
              commissioned me to do.”
            </p>
          </blockquote>
        </div>

        <div className="max-w-5xl px-4 mx-auto mt-12 sm:px-6 lg:px-8">
          <p className="mt-6 text-lg leading-relaxed text-gray-700">
            Finally, I felt like I understood the answer God had continued to
            give me. Christ is Lord over everything. I could spend all the hours
            of my life working to build a company, or get promotions and make
            more money, but neither I, nor my family, nor my God need those
            things neaarly as much as we need to be united with God. I have more
            to do in life than seek material provisions. I want what He wants
            for me: to be a good disciple.
          </p>
          <p className="mt-6 text-lg leading-relaxed text-gray-700">
            <em>Discipleship</em> is an app designed to support others on their
            journey to becoming true disciples of Jesus Christ. As my family and
            friends share with me ideas that would help them, I build them into
            this platform. I can provide tools, resources, and a community that
            fosters spiritual growth and commitment, and the Spirit can take it
            from there.
          </p>
          {/* <p className="mt-6 text-lg leading-relaxed text-gray-700">
            I invite you to help me in this mission. Any contribution, no matter
            the size, will directly impact the development of these discipleship
            tools and help provide for my family's basic needs.
          </p>
          <p className="mt-6 text-lg leading-relaxed text-gray-700">
            To be respectful to contributors, you'll find progress bars below
            representing the fulfillment of these needs, ensuring that your
            generosity is used wisely, and with accountability.
          </p> */}

          {/* <div className="mt-12 text-center">
            <a
              href="#donate"
              className="inline-block px-8 py-4 text-lg font-semibold text-white bg-blue-600 rounded-full hover:bg-blue-700"
            >
              Support the Mission
            </a>
          </div> */}
        </div>

        {/* <div className="max-w-5xl px-4 mx-auto mt-16 sm:px-6 lg:px-8">
          <BudgetProgress />
        </div> */}

        <div className="max-w-5xl px-4 mx-auto mt-16 sm:px-6 lg:px-8">
          <div className="text-center">
            <h3 className="text-2xl font-semibold text-gray-800">
              Have a Question or Want to Connect?
            </h3>
            <p className="mt-4 text-lg text-gray-700">
              I'd love to hear from you! Reach out to me on{" "}
              <a
                href="https://discord.com/invite/your-discord-link"
                className="text-blue-600 hover:text-blue-700"
              >
                Discord
              </a>{" "}
              and let's start a conversation.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default About;
