import React from "react";
import { FaDiscord } from "react-icons/fa";
/* import { FaXTwitter, FaInstagram, FaTiktok } from "react-icons/fa6"; */

const Footer = () => {
  return (
    <footer className="border bg-discipleship border-faithful-dark">
      <div className="container px-6 py-10 mx-auto">
        {/* Top Section: Logo and Navigation */}
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-6">
          {/* Logo and Description */}
          <div className="col-span-2 mr-12">
            <a href="/" className="flex items-center mb-4">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/christlike-attributes-c0fd3.appspot.com/o/discipleship-official%2Fdisciplesheep.png?alt=media&token=377e611c-0fac-4461-b959-32ca7d5f57e8"
                alt="Discipleship Logo"
                className="w-16 h-16 mr-2"
              />
              <span className="text-xl font-semibold">Discipleship</span>
            </a>
            <p className="text-sm">
              Helping you grow in faith and follow the path of Christ.
            </p>
          </div>

          {/* Navigation Sections */}
          {/* App Features Section */}
          <div className="col-span-2">
            <h3 className="mb-4 text-sm font-semibold uppercase">Features</h3>
            <ul className="">
              <li className="mb-2">
                <a href="/self-assessment" className="hover:underline">
                  Self-Assessment
                </a>
              </li>
              <li className="mb-2">
                <a href="/articles" className="hover:underline">
                  <s>What Lack I Yet?</s> (Coming Soon!)
                </a>
              </li>
              <li className="mb-2">
                <a href="/resources" className="hover:underline">
                  <s>My Study Plan</s> (Coming Soon!)
                </a>
              </li>
              <li className="mb-2">
                <a href="/resources" className="hover:underline">
                  <s>Global Discipleship</s> (Coming Soon!)
                </a>
              </li>
            </ul>
          </div>

          {/* Connect Section */}
          <div>
            <h3 className="mb-4 text-sm font-semibold uppercase">Connect</h3>
            <ul className="">
              <li className="mb-2">
                <a
                  href={`${process.env.REACT_APP_DISCORD_INVITE}`}
                  className="hover:underline"
                >
                  Discord Server
                </a>
              </li>
              <li className="mb-2">
                <a
                  href={`${process.env.REACT_APP_REDDIT}`}
                  className="hover:underline"
                >
                  Subreddit
                </a>
              </li>
              <li className="mb-2">
                <a
                  href={`${process.env.REACT_APP_CANNY}`}
                  className="hover:underline"
                >
                  Roadmap & Feature Requests
                </a>
              </li>
            </ul>
          </div>

          {/* About Us Section */}
          <div>
            <h3 className="mb-4 text-sm font-semibold uppercase">About</h3>
            <ul className="">
              <li className="mb-2">
                <a href="/about" className="hover:underline">
                  My Story
                </a>
              </li>
              <li className="mb-2">
                <a href="about" className="hover:underline">
                  <s>Donate</s> (Coming Soon!)
                </a>
              </li>
              <li className="mb-2">
                <a href="#" className="hover:underline">
                  <s>Join My Team</s> (Coming Soon!)
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Divider */}
        <hr className="w-full m-6 mx-auto border-wise-dark" />

        {/* Bottom Section: Standard Links and Social Media */}
        <div className="flex flex-col items-center justify-between md:flex-row">
          {/* Standard Links */}
          <div className="flex flex-wrap justify-center mb-4 md:mb-0">
            <a
              href="mailto:support@pathtochrist.life"
              className="mr-4 text-sm hover:underline"
            >
              Email
            </a>
            <a href="/privacy-policy" className="mr-4 text-sm hover:underline">
              Privacy Policy
            </a>
            <a href="/terms-of-service" className="text-sm hover:underline">
              Terms of Service
            </a>
          </div>

          {/* Social Media Links */}
          <div className="flex space-x-6">
            <a
              href={`${process.env.REACT_APP_DISCORD_INVITE}`}
              className=" hover"
              aria-label="Discord"
            >
              <FaDiscord />
            </a>
            {/* <a
              href="#"
              className=" hover"
              aria-label="Facebook"
            >
              <FaFacebookF />
            </a> */}
            {/* <a
              href="#"
              className=" hover"
              aria-label="Twitter"
            >
              <FaXTwitter />
            </a> */}
            {/* <a
              href="#"
              className=" hover"
              aria-label="Instagram"
            >
              <FaInstagram />
            </a> */}
            {/* <a
              href="#"
              className=" hover"
              aria-label="TikTok"
            >
              <FaTiktok />
            </a> */}
          </div>
          <p className="text-sm ">
            © {new Date().getFullYear()} Path to Christ. All rights reserved.
          </p>
        </div>

        {/* Footer Bottom */}
        <div className="mt-6 text-center"></div>
      </div>
    </footer>
  );
};

export default Footer;
