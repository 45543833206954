import React from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const RadarChartComponent = ({ data }) => {
  if (!data || data.length === 0) return <p>No data available.</p>;

  return (
    <div className="container p-6 mx-auto my-8 bg-white rounded-lg shadow-lg">
      <h3 className="mb-6 text-2xl font-semibold text-center text-gray-800">
        Performance Radar Chart
      </h3>
      <ResponsiveContainer width="100%" height={400}>
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
          <PolarGrid stroke="#ccc" />
          <PolarAngleAxis dataKey="section" tick={{ fontSize: 12 }} />
          <PolarRadiusAxis angle={30} domain={[0, 5]} tick={{ fontSize: 12 }} />
          <Tooltip />
          <Radar
            name="Average Score"
            dataKey="average"
            stroke="#4A90E2"
            fill="#4A90E2"
            fillOpacity={0.6}
          />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RadarChartComponent;
