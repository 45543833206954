import React from "react";
import { Link } from "react-router-dom";

const AppBar = () => {
  const navItems = [
    { name: "Home", imgSrc: "https://firebasestorage.googleapis.com/v0/b/christlike-attributes-c0fd3.appspot.com/o/discipleship-official%2Fdisciplesheep.png?alt=media&token=377e611c-0fac-4461-b959-32ca7d5f57e8", link: "/" },
    { name: "Assessment", imgSrc: "https://firebasestorage.googleapis.com/v0/b/christlike-attributes-c0fd3.appspot.com/o/discipleship-official%2Fcat-3-scriptures.png?alt=media&token=6f524475-d494-4feb-92eb-16d1b494d3bb", link: "/self-assessment" },
    { name: "About", imgSrc: "https://firebasestorage.googleapis.com/v0/b/christlike-attributes-c0fd3.appspot.com/o/discipleship-official%2Fcat-2-prophets.png?alt=media&token=257090e8-bc1a-4720-af35-f6d4fbd019ad", link: "/about" },
    /* { name: "Sheep", imgSrc: "https://firebasestorage.googleapis.com/v0/b/christlike-attributes-c0fd3.appspot.com/o/discipleship-official%2Fdisciplephant.png?alt=media&token=b5170408-f025-4f41-b2e0-712951c1dfd7", link: "/cats" }, */
    /* { name: "Logout", imgSrc: "https://firebasestorage.googleapis.com/v0/b/christlike-attributes-c0fd3.appspot.com/o/discipleship-official%2Fcat-2-prophets.png?alt=media&token=257090e8-bc1a-4720-af35-f6d4fbd019ad", link: "/auth" }, */
  ];

  return (
    <div className="flex items-center p-2 space-x-4 overflow-x-auto bg-discipleship">
      {navItems.map((item) => (
        <Link to={item.link} key={item.name} className="flex flex-col items-center">
          <div className="w-16 h-16 bg-transparent">
            <img src={item.imgSrc} alt={item.name} className="w-full h-full rounded-md shadow-md" />
          </div>
          <span className="mt-2 text-sm text-gray-600">{item.name}</span>
        </Link>
      ))}
    </div>
  );
};

export default AppBar;
