import React from "react";

const ProgressBar = ({ progress }) => {
  return (
    <div className="flex items-center justify-center w-full mb-6 bg-transparent h-fit">
      <div className="relative w-3/4 h-8 max-w-xl bg-white border-2 border-black rounded-full shadow-md">
        <div
          className="absolute top-0 left-0 h-full rounded-full bg-lochinvar-dark"
          style={{ width: `${progress}%` }}
        ></div>
        <div className="absolute top-0 flex items-center h-full text-lg font-bold text-black right-4">
          {progress}%
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
