import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../components/constants/Navbar";
import Footer from "../components/constants/Footer";
import SectionHeader from "../components/constants/SectionHeader";
import ProfileInfoCard from "../components/ProfileInfoCard";
import OverallStatsGraph from "../components/charts/OverallStatsGraph";
import UserStats from "../components/charts/UserStats";

import { fetchUserProfileData } from "../store/actions/userActions";
import axios from "axios";
import AppBar from "../components/constants/AppBar";

/* const SectionCard = ({ section, average, median, bgColor }) => {
  return (
    <div className={`p-6 rounded-lg shadow-md text-white ${bgColor}`}>
      <div className="mb-2 text-lg font-semibold">{section}</div>
      <div className="flex justify-between mb-1">
        <span>Average:</span>
        <span>{average.toFixed(1)}</span>
      </div>
      <div className="flex justify-between">
        <span>Median:</span>
        <span>{median.toFixed(0)}</span>
      </div>
    </div>
  );
}; */

const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const userProfileData = useSelector((state) => state.user.userProfileData);

  const [overallStats, setOverallStats] = useState({});
  const [sectionStats, setSectionStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortType, setSortType] = useState("none");

  const [profileData, setProfileData] = useState({
    email: user?.email || "",
    displayName: user?.displayName || "",
    photoURL: user?.photoURL || "",
  });

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  // Fetch User Answers
  const fetchUserAnswers = async () => {
    if (!user?.uid) {
      console.error("User not logged in.");
      return;
    }

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/answers/${user.uid}`
      );
      console.log("User Answers", res.data);
      processAnswers(res.data);
    } catch (error) {
      console.error("Error fetching user answers:", error);
      setError("Failed to fetch user answers.");
    } finally {
      setLoading(false);
    }
  };

  // Clear messages after 3 seconds
  useEffect(() => {
    if (message || error) {
      const timer = setTimeout(() => {
        setMessage("");
        setError("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [message, error]); // runs when `message` or `error` changes

  // Fetch User Profile Data
  useEffect(() => {
    if (user?.uid) {
      if (!userProfileData) {
        dispatch(fetchUserProfileData(user.uid));
      }
      fetchUserAnswers();
    }
  }, [user, userProfileData, dispatch]);

  const processAnswers = (answers) => {
    const totalAnswers = answers.length;
    const totalPossible = totalAnswers * 5;
    let scoreTotal = 0;

    const scoresBySection = {
      Faithful: [],
      Pure: [],
      Loving: [],
      Hopeful: [],
      Patient: [],
      Meek: [],
      True: [],
      Wise: [],
      Grateful: [],
      Joyous: [],
      Merciful: [],
      Peaceful: [],
    };

    const allScores = [];

    // Go through each answer and do whatever you want with it
    answers.forEach((answer) => {
      const score = answer.score;
      const section = answer.question.mod;

      scoreTotal += score;
      allScores.push(score);
      if (scoresBySection[section]) {
        scoresBySection[section].push(score);
      } else {
        // Handle any unexpected sections
        scoresBySection[section] = [score];
      }
    });

    // Helper function to calculate the median
    const calculateMedian = (scores) => {
      if (scores.length === 0) return 0;
      const sortedScores = [...scores].sort((a, b) => a - b);
      const middle = Math.floor(sortedScores.length / 2);

      return sortedScores.length % 2 !== 0
        ? sortedScores[middle]
        : (sortedScores[middle - 1] + sortedScores[middle]) / 2;
    };

    setOverallStats({
      totalAnswers,
      scoreTotal,
      totalPossible,
      medianAllScores: calculateMedian(allScores),
    });

    const sectionStats = Object.keys(scoresBySection).map((section) => {
      const sectionScores = scoresBySection[section];
      const average =
        sectionScores.reduce((a, b) => a + b, 0) / sectionScores.length || 0;
      const median = calculateMedian(sectionScores);
      return { section, average, median };
    });

    setSectionStats(sectionStats);
  };

  // Background color mapping
  /*  const sectionColors = {
    Faithful: "bg-faithful",
    Pure: "bg-pure",
    Loving: "bg-loving",
    Hopeful: "bg-hopeful",
    Patient: "bg-patient",
    Meek: "bg-meek",
    True: "bg-true",
    Wise: "bg-wise",
    Grateful: "bg-grateful",
    Joyous: "bg-joyous",
    Merciful: "bg-merciful",
    Peaceful: "bg-peaceful",
  }; */

  const sortedData = useMemo(() => {
    return [...sectionStats].sort((a, b) => {
      if (sortType === "name-asc") return a.section.localeCompare(b.section);
      if (sortType === "name-desc") return b.section.localeCompare(a.section);
      if (sortType === "score-asc") return a.average - b.average;
      if (sortType === "score-desc") return b.average - a.average;
      return 0;
    });
  }, [sortType, sectionStats]);

  const totalQuestions = overallStats.totalAnswers || 0;
  const totalScore = overallStats.scoreTotal || 0;
  const totalPossible = overallStats.totalPossible || 0;
  const percentage = totalPossible
    ? ((totalScore / totalPossible) * 100).toFixed(0)
    : 0;
  const averageAnswer = totalQuestions
    ? (totalScore / totalQuestions).toFixed(1)
    : 0;
  const medianAnswer = overallStats.medianAllScores
    ? overallStats.medianAllScores.toFixed(0)
    : 0;
  /* const timeTaken = 0; // TODO: calculate time taken
  const dateCompleted = 0; // TODO: calculate date completed
  const rank = 0; // TODO: calculate rank
  const comparisonToPrevious = 0; // TODO: calculate comparison to previous */

  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar />
      <AppBar />
      <div className="container py-8 mx-auto">
        <ProfileInfoCard
          user={user}
          profileData={profileData}
          fetchUserProfile={(uid) => dispatch(fetchUserProfileData(uid))}
          setProfileData={setProfileData}
          setMessage={setMessage}
          setError={setError}
        />

        <SectionHeader variant="primary" title="Overall Performance" />

        <div className="flex flex-col items-center">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div className="w-full max-w-md p-6 mb-8 overflow-hidden bg-white rounded-lg shadow-lg">
              <div className="mb-6 text-2xl font-semibold text-center text-gray-800">
                Assessment Summary
              </div>

              <div className="flex justify-between mb-4">
                <span>Total Questions:</span>
                <span>{totalQuestions}</span>
              </div>

              <div className="flex justify-between mb-4">
                <span>Total Score:</span>
                <span>{totalScore}</span>
              </div>

              <div className="flex justify-between mb-4">
                <span>Total Possible:</span>
                <span>{totalPossible}</span>
              </div>

              <div className="flex justify-between mb-4">
                <span>Percentage:</span>
                <span>{percentage}%</span>
              </div>

              <div className="flex justify-between mb-4">
                <span>Average Answer:</span>
                <span>{averageAnswer}</span>
              </div>

              <div className="flex justify-between mb-4">
                <span>Median Answer:</span>
                <span>{medianAnswer}</span>
              </div>

              {/* Uncomment to make optional */}
              {/* {timeTaken && ( */}
              {/* <div className="flex justify-between mb-2">
              <span>Time Taken:</span>
              <span>{timeTaken} mins</span>
            </div> */}
              {/* )} */}

              {/* {dateCompleted && (
              <div className="flex justify-between mb-2">
                <span>Date Completed:</span>
                <span>{dateCompleted}</span>
              </div>
            )} */}

              {/* {rank && (
              <div className="flex justify-between mb-2">
                <span>Rank:</span>
                <span>{rank}</span>
              </div>
            )} */}

              {/* {comparisonToPrevious && (
              <div className="flex justify-between mb-2">
                <span>Comparison to Previous Quiz:</span>
                <span>
                  {comparisonToPrevious > 0
                    ? `+${comparisonToPrevious}%`
                    : `${comparisonToPrevious}%`}
                </span>
              </div>
            )} */}
            </div>
          )}
        </div>

        <SectionHeader variant="primary" title="Marks of Discipleship" />
        <UserStats sortedData={sortedData} setSortType={setSortType} />
        <OverallStatsGraph sectionStats={sortedData} />

        {/* <div className="grid grid-cols-1 gap-6 px-4 mb-8 md:grid-cols-2 lg:grid-cols-3">
          {loading ? (
            <p>Loading...</p>
          ) : (
            sortedData.map(({ section, average, median }) => (
              <SectionCard
                key={section}
                section={section}
                average={average}
                median={median}
                bgColor={sectionColors[section] || "bg-gray-500"}
              />
            ))
          )}
        </div> */}
      </div>

      <Footer />
    </div>
  );
};

export default Profile;
