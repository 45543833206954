import React, { useState } from "react";
import { Cell, PieChart, Pie, ResponsiveContainer } from "recharts";
import {
  FaHeart,
  FaSeedling,
  FaPrayingHands,
  FaAnchor,
  FaEye,
  FaShieldAlt,
  FaBookOpen,
  FaHandPaper,
  FaHourglassHalf,
  FaGem,
} from "react-icons/fa";

// Function to get the appropriate icon based on the section name
function getIcon(name) {
  switch (name) {
    case "Faithful":
      return <FaPrayingHands />;
    case "Pure":
      return <FaGem />;
    case "Loving":
      return <FaHeart />;
    case "Hopeful":
      return <FaAnchor />;
    case "Patient":
      return <FaHourglassHalf />;
    case "Meek":
      return <FaEye />;
    case "True":
      return <FaShieldAlt />;
    case "Wise":
      return <FaBookOpen />;
    case "Grateful":
      return <FaBookOpen />;
    case "Joyous":
      return <FaSeedling />;
    case "Merciful":
      return <FaHandPaper />;
    case "Peaceful":
      return <FaHandPaper />;
    default:
      return <FaPrayingHands />;
  }
}

const sectionColors = {
  Faithful: "#95CBE4",
  Pure: "#DCE5EF",
  Loving: "#EF837B",
  Hopeful: "#C8EA6C",
  Patient: "#38b2ac",
  Meek: "#C1B1A8",
  True: "#EBD224",
  Wise: "#FFE55D",
  Grateful: "#F3A616",
  Joyous: "#FFD700",
  Merciful: "#2F9393",
  Peaceful: "#ADD8E6",
};

const ProgressRingsComponent = ({ data }) => {
  console.log("Progress Rings Data", data);
  const [displayPercentage, setDisplayPercentage] = useState({});

  if (!data || data.length === 0) {
    return <p>No data available for Progress Rings.</p>;
  }

  const handleIconClick = (index) => {
    setDisplayPercentage((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div className="container grid grid-cols-1 gap-6 p-4 mx-auto my-8 bg-white rounded-lg shadow-lg sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      <h3 className="mb-6 text-2xl font-semibold text-center text-gray-800 col-span-full">
        Section Progress Rings
      </h3>
      {data.map((item, index) => {
        // Calculate percentage score for the section
        const percentage = ((item.average / 5) * 100).toFixed(1);
        return (
          <div
            key={index}
            className="flex flex-col items-center p-4 rounded-lg shadow-lg bg-gray-50"
          >
            <div className="relative flex items-center justify-center w-full h-40">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={[
                      { name: item.section, value: item.average },
                      { name: "Remaining", value: 5 - item.average },
                    ]}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    innerRadius={50}
                    outerRadius={70}
                    startAngle={90}
                    endAngle={-270}
                  >
                    <Cell
                      fill={sectionColors[item.section] || "#4A90E2"}
                      stroke="none"
                    />
                    <Cell fill="#E0E0E0" stroke="none" />
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              <div
                className="absolute text-3xl text-gray-700 cursor-pointer"
                onClick={() => handleIconClick(index)}
              >
                {displayPercentage[index]
                  ? `${percentage}%`
                  : getIcon(item.section)}
              </div>
            </div>
            <p className="mt-4 text-xl font-bold text-center text-gray-800">
              {item.section}
            </p>
            <p className="text-lg text-center text-gray-600">
              {displayPercentage[index] ? `${item.average.toFixed(1)} / 5` : ""}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default ProgressRingsComponent;
