import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const OverallStatsGraph = ({ sectionStats }) => {
  return (
    <div className="container p-6 mx-auto my-8 bg-white rounded-lg shadow-lg">
      <h3 className="mb-6 text-2xl font-semibold text-center text-gray-800">
        Overall Performance by Section
      </h3>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={sectionStats}
          margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
          barCategoryGap="20%"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="section" tick={{ fontSize: 12 }} />
          <YAxis domain={[0, 5]} tick={{ fontSize: 12 }} />
          <Tooltip />
          <Bar dataKey="average" fill="#4A90E2" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default OverallStatsGraph;
