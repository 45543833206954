import AppBar from "../components/constants/AppBar";
import Navbar from "../components/constants/Navbar";
import Hero from "../components/Hero";
import Footer from "../components/constants/Footer";

const Main = () => (
  <div className="flex flex-col min-h-screen">
    <Navbar />
    <AppBar />
    <Hero />
    <Footer />
  </div>
);

export default Main;
