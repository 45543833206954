import { createBrowserRouter } from "react-router-dom";
import About from "./pages/About";
import AuthPage from "./pages/AuthPage";
import ErrorPage from "./pages/ErrorPage";
import Main from "./pages/Main";
import MarksOfDiscipleship from "./pages/MarksOfDiscipleship";
import NotFound from "./pages/NotFound";
import PageTracker from "./components/PageTracker";
import PrivateRoute from "./components/PrivateRoute";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Profile from "./pages/Profile";
import SelfAssessment from "./pages/SelfAssessment";
import SettingsPage from "./pages/SettingsPage";
import TermsOfService from "./pages/TermsOfService";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateRoute>
        <PageTracker>
          <Main />
        </PageTracker>
      </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/about",
    element: (
      <PageTracker>
        <About />
      </PageTracker>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/auth",
    element: (
      <PageTracker>
        <AuthPage />
      </PageTracker>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/marks-of-discipleship",
    element: (
      <PageTracker>
        <MarksOfDiscipleship />
      </PageTracker>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/privacy-policy",
    element: (
      <PageTracker>
        <PrivacyPolicy />
      </PageTracker>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/profile",
    element: (
      <PrivateRoute>
        <PageTracker>
          <Profile />
        </PageTracker>
      </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/results",
    element: (
      <PrivateRoute>
        <PageTracker>
          <Profile />
        </PageTracker>
      </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/self-assessment",
    element: (
      <PrivateRoute>
        <PageTracker>
          <SelfAssessment />
        </PageTracker>
      </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/settings",
    element: (
      <PrivateRoute>
        <PageTracker>
          <SettingsPage />
        </PageTracker>
      </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/tos",
    element: (
        <PageTracker>
          <TermsOfService />
        </PageTracker>
    ),
    errorElement: <ErrorPage />,
  },

  // Scripture Viewer
  /*   {
    path: "/scriptures",
    element: <ScriptureViewer data={data} />,
  },
  {
    path: "/scriptures/:book/:chapter",
    element: <ScriptureViewer data={data} />,
  },
  {
    path: "/scriptures/:book/:chapter/:verse",
    element: <ScriptureViewer data={data} />,
  }, */

  // Utility pages
  {
    path: "/404",
    element: (
      <PageTracker>
        <NotFound />
      </PageTracker>
    ),
  },
  {
    path: "*",
    element: (
      <PageTracker>
        <NotFound />
      </PageTracker>
    ),
  },
]);

export default router;
