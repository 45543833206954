import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAuth } from "firebase/auth";
import { logout } from "../../store/reducers/userSlice";
import { removeUserFromCache } from "../../utils/localCache";

const Navbar = ({ loading }) => {
  const user = useSelector((state) => state.user.user);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const auth = getAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      removeUserFromCache();
      dispatch(logout());
      navigate("/auth");
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav>
      <div className="">
        <div className="flex flex-wrap p-2 text-black bg-faithful-dark">
          {!loading && user ? (
            <div className="relative" ref={dropdownRef}>
              <button
                className="flex items-center focus:outline-none"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                aria-haspopup="true"
                aria-expanded={dropdownOpen}
              >
                <img
                  className="w-6 h-6 mx-2 rounded-full"
                  src={
                    user.photoURL ||
                    "https://firebasestorage.googleapis.com/v0/b/christlike-attributes-c0fd3.appspot.com/o/profilePictures%2Fprofile.png?alt=media&token=00ad0577-b2e7-4e94-ab0b-ac217a14a0d1"
                  }
                  alt="user avatar"
                />
                <span className="font-heading">
                  {user.displayName || user.email}
                </span>
              </button>
              {dropdownOpen && (
                <div className="absolute right-0 z-10 mt-2 bg-white rounded-md shadow-lg">
                  <Link
                    to="/profile"
                    className="block px-4 py-2 text-black rounded hover:bg-gray-100"
                  >
                    Profile
                  </Link>
                  <Link
                    to="/settings"
                    className="block px-4 py-2 text-black rounded hover:bg-gray-100">
                    Settings
                    </Link>
                  <button
                    onClick={handleLogout}
                    className="block w-full px-4 py-2 text-left text-black rounded hover:bg-gray-100"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          ) : (
            !loading && (
              <Link
                to="/auth"
                className="px-4 py-2 text-white rounded bg-outrageous-500"
              >
                Sign In
              </Link>
            )
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
