import React from 'react';

const SectionHeader = ({ variant, title, subtitle }) => {
  const headerStyles = {
    primary: "py-6 mb-6 text-center text-white rounded-lg shadow-md bg-faithful-dark",
    secondary: "py-6 mb-6 text-center text-white rounded-lg shadow-md bg-hopeful",
    yellow: "py-6 mb-6 text-center text-white rounded-lg shadow-md bg-grateful",
    outline: "py-6 mb-6 border rounded-lg border-faithful-dark text-faithful-dark",
  };

  return (
    <div className={`${headerStyles[variant]}`}>
      <h1 className="text-3xl font-extrabold">{title}</h1>
      {subtitle && <p className="mt-2 text-lg">{subtitle}</p>}
    </div>
  );
};

export default SectionHeader;
