const Hero = () => (
  <div className="flex flex-col items-center justify-center w-full h-full my-auto overflow-hidden">
    <div className="flex flex-col items-center w-full pt-4 lg:flex-row">
      <div className="flex flex-col items-center justify-center w-full p-6 text-center lg:text-left lg:w-1/2 lg:items-start">
        <div className="p-6 bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
          <h1 className="mb-4 text-3xl font-extrabold leading-tight tracking-tight text-gray-900 md:text-4xl lg:text-5xl dark:text-white">
            Embark on Your Path to{" "}
            <span className="underline decoration-8 decoration-faithful-dark dark:decoration-faithful-light">
              Christlike Discipleship.
            </span>
          </h1>
          <p className="mt-2 text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">
            Thank you for being here.
          </p>
          <div className="flex flex-col items-center mt-10 space-y-3 sm:space-y-0 sm:flex-row sm:space-x-3">
            <a
              href="/self-assessment"
              className="px-6 py-3 text-sm font-semibold text-white uppercase transition duration-200 border rounded-lg border-faithful-dark bg-gradient-to-r from-faithful-dark via-faithful-semidark to-faithful hover:bg-gradient-to-br focus:ring-4 focus:ring-faithful dark:focus:ring-faithful-dark"
            >
              Take the Test
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Hero;
