import React from "react";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import useAuth from "./hooks/useAuth";

import { useSelector } from "react-redux";
import { selectUser } from "./store/reducers/userSlice";

const AuthPage = React.lazy(() => import("./pages/AuthPage"));
const Main = React.lazy(() => import("./pages/Main"));

function App() {
  const user = useSelector(selectUser);
  const { loading: authLoading } = useAuth();

  if (authLoading) {
    return <div>Loading...</div>;
  }

  return (
      <React.Suspense fallback={<div>Loading...</div>}>
        <RouterProvider router={router}>
          {user ? <Main /> : <AuthPage />}
        </RouterProvider>
      </React.Suspense>
  );
}

export default App;
