import React from "react";
import RadarChartComponent from "./RadarChartComponent";
import ProgressRingsComponent from "./ProgressRingsComponent";

const UserStats = ({ sortedData, setSortType }) => {
  return (
    <div className="container p-6 mx-auto my-8 bg-white rounded-lg shadow-lg">
      <h3 className="mb-6 text-2xl font-semibold text-center text-gray-800">
        Your Assessment Results
      </h3>
      <div className="flex justify-center mb-6">
        <select
          onChange={(e) => setSortType(e.target.value)}
          className="p-2 border border-gray-300 rounded-md"
          aria-label="Sort results"
        >
          <option value="none">Sort by</option>
          <option value="name-asc">Name (A-Z)</option>
          <option value="name-desc">Name (Z-A)</option>
          <option value="score-asc">Score (Low to High)</option>
          <option value="score-desc">Score (High to Low)</option>
        </select>
      </div>
      <div className="my-8">
        <ProgressRingsComponent data={sortedData} />
      </div>
      <div className="my-8">
        <RadarChartComponent data={sortedData} />
      </div>
    </div>
  );
};

export default UserStats;
