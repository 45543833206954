import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

import ProgressBar from "../components/constants/ProgressBar";
import Footer from "../components/constants/Footer";
import Navbar from "../components/constants/Navbar";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const getSectionColor = (section) => {
  const colors = {
    Faithful: "#95CBE4",
    Pure: "#DCE5EF",
    Loving: "#EF837B",
    Hopeful: "#C8EA6C",
    Patient: "#38b2ac",
    Meek: "#C1B1A8",
    True: "#EBD224",
    Wise: "#FFE55D",
    Grateful: "#F3A616",
    Joyous: "#FFE55D",
    Merciful: "#2F9393",
    Peaceful: "#95CBE4",
  };

  return colors[section] || "#ffffff";
};

const SelfAssessment = () => {
  // State variables
  const user = useSelector((state) => state.user.user);

  const [nextQuestion, setNextQuestion] = useState(null);
  const [nextQuestionNum, setNextQuestionNum] = useState(0);
  const [nextQuestionId, setNextQuestionId] = useState(null);
  const [questionSet, setQuestionSet] = useState([]);
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState("");

  const attemptRef = useRef(null);
  const navigate = useNavigate();

  // Database functions
  const createOrFindAttempt = async () => {
    console.log("Creating or finding attempt for user", user);

    try {
      // Try to find existing attempt
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/attempt/${user.uid}`
      );

      if (response.data) {
        console.log("Attempt found:", response.data);
        attemptRef.current = response.data;
        return response.data;
      } else {
        console.log("Creating new attempt");
        const newResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/attempt/${user.uid}`
        );
        console.log("New attempt:", newResponse.data);
        attemptRef.current = newResponse.data;
        return newResponse.data;
      }
    } catch (error) {
      console.error("Error creating or finding attempt:", error);
      return null;
    }
  };

  const saveUserAnswer = async (questionId, score) => {
    console.log("Saving user answer for question", questionId);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/answers/save-answer/${user.uid}/${questionId}`,
        { score }
      );
      console.log("User answer saved successfully", score);
    } catch (error) {
      console.error("Error saving response:", error);
    }
  };

  // User action handlers
  const handleOptionChange = (e) => {
    setResponse(e.target.value);
  };

  const handlePrev = () => {
    if (nextQuestionNum > 0) {
      const newQuestionNum = nextQuestionNum - 1;
      setNextQuestionNum(newQuestionNum);
      setNextQuestion(questionSet[newQuestionNum]);
      setNextQuestionId(questionSet[newQuestionNum]._id);
      setResponse(""); // Optionally reset response if needed
    }
  };

  const handleNext = async () => {
    if (!response) {
      alert("Please answer the question before proceeding.");
      return;
    }

    // Capture the response value before any asynchronous operations
    const currentResponse = response;
    const currentQuestionId = nextQuestionId;

    try {
      const numericResponse = Number(currentResponse);
      // Save response to the current question in attempt doc
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/attempt/${attemptRef.current._id}/save-response`,
        { questionId: currentQuestionId, response: numericResponse }
      );
      await saveUserAnswer(currentQuestionId, numericResponse);
    } catch (error) {
      console.error("Error saving response:", error);
    }

    if (nextQuestionNum < questionSet.length - 1) {
      console.log(
        "Assessment continues, Next question number:",
        nextQuestionNum + 1
      );
      const newQuestionNum = nextQuestionNum + 1;
      setNextQuestionNum(newQuestionNum);
      setNextQuestion(questionSet[newQuestionNum]);
      setNextQuestionId(questionSet[newQuestionNum]._id);
    } else {
      console.log("Assessment finished. Calling finishAttempt");
      try {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/api/attempt/${attemptRef.current._id}/finish`
        );
        navigate("/results");
      } catch (error) {
        console.error("Error finishing attempt:", error);
      }
    }

    setResponse(""); // Reset response after handling
  };

  const setUpAssessment = async () => {
    try {
      const attempt = await createOrFindAttempt();
      if (!attempt) {
        throw new Error("Attempt creation or retrieval failed");
      }

      console.log("Setting up assessment with attempt:", attempt);
      const answered = attempt.qSet.answered || 0;
      setNextQuestionNum(answered);

      // Fetch all questions and filter the ones in the attempt
      const fetchedQuestions = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/questions`
      );
      const filteredQuestions = fetchedQuestions.data.filter((q) =>
        attempt.qSet.qs.includes(q._id)
      );

      setQuestionSet(filteredQuestions);
      setNextQuestion(filteredQuestions[answered]);
      setNextQuestionId(filteredQuestions[answered]._id);
    } catch (error) {
      console.error("Error setting up assessment:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.uid) {
      setUpAssessment();
    }
  }, [user]);

  const isQuestionAvailable = nextQuestion !== null;
  const currentSection = isQuestionAvailable ? nextQuestion.mod : "";
  const progressPercentage = questionSet.length
    ? Math.floor((nextQuestionNum / questionSet.length) * 100)
    : 0;

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p className="text-2xl text-gray-800">Loading...</p>
      </div>
    );
  }

  return (
    <div>
      <Navbar />

      {/* Main Container */}
      <div
        style={{ backgroundColor: getSectionColor(currentSection) }}
        className="container max-w-screen-lg p-4 mx-auto my-6 rounded-lg shadow-lg sm:p-6"
      >
        <h2 className="mb-4 text-3xl font-bold text-center text-gray-800 md:text-4xl">
          Be ye {currentSection}
        </h2>
        <div className="mb-6">
          <ProgressBar progress={progressPercentage} />
        </div>

        {/* Question Section */}
        <div id="question-section">
          {isQuestionAvailable && (
            <div className="flex flex-col items-center justify-center p-4 my-4 bg-transparent h-fit">
              <div className="w-full max-w-md p-6 bg-white border rounded-lg shadow-lg border-meek-dark md:w-3/4 lg:w-2/5">
                <h2 className="mb-3 text-xl font-bold text-gray-800">
                  {nextQuestion.qText}
                </h2>
                <div className="flex flex-row justify-between mb-4">
                  {[1, 2, 3, 4, 5].map((option) => (
                    <label key={option} className="flex items-center space-x-1">
                      <input
                        type="radio"
                        name={`question-${nextQuestionId}`}
                        value={option}
                        checked={response === option.toString()}
                        className="w-5 h-5 rounded-full cursor-pointer focus:ring-2 focus:ring-faithful text-faithful-dark border-meek"
                        onChange={handleOptionChange}
                        aria-label={`Option ${option} for question ${nextQuestionId}`}
                      />
                      <span className="text-lg text-gray-700">{option}</span>
                    </label>
                  ))}
                </div>

                {/* Safely access reference */}
                {nextQuestion.reference?.[0]?.rText && (
                  <div className="mt-2 text-sm italic text-center text-gray-500">
                    <p>{nextQuestion.reference[0].rText}</p>
                    <div className="mt-2 font-bold">
                      {nextQuestion.reference[0].scripture.book}{" "}
                      {nextQuestion.reference[0].scripture.chapter}:
                      {nextQuestion.reference[0].scripture.verse}
                    </div>
                  </div>
                )}

                <div className="flex items-center justify-between mt-6">
                  <button
                    onClick={handlePrev}
                    disabled={nextQuestionNum === 0}
                    className={`text-gray-600 hover:text-gray-800 ${
                      nextQuestionNum === 0
                        ? "cursor-not-allowed opacity-50"
                        : ""
                    }`}
                  >
                    <FaArrowLeft className="w-6 h-6" />
                  </button>
                  <span className="text-lg font-semibold text-gray-800">
                    {nextQuestionNum + 1} of {questionSet.length}
                  </span>
                  {nextQuestionNum < questionSet.length - 1 ? (
                    <button
                      onClick={handleNext}
                      className="px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                      disabled={!response}
                    >
                      <FaArrowRight className="w-6 h-6" />
                    </button>
                  ) : (
                    <button
                      onClick={handleNext}
                      className="px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600 disabled:opacity-50 disabled:cursor-not-allowed"
                      disabled={!response}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SelfAssessment;
