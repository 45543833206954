// src/pages/SettingsPage.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdEmail } from "react-icons/md";
import { FaUserAlt, FaEdit } from "react-icons/fa";

import Navbar from "../components/constants/Navbar";
import AppBar from "../components/constants/AppBar";
import {
  updateUserProfile,
  updateUserProfilePhoto,
} from "../store/actions/userActions";
import { setUserToCache } from "../utils/localCache";
import { storage } from "../firebase/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import axios from "axios";
import Footer from "../components/constants/Footer";

const SettingsPage = () => {
  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.user.user);
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState("profile");
  const [profileData, setProfileData] = useState({});
  const [privacySettings, setPrivacySettings] = useState({
    showEmail: true,
    showProfileToPublic: true,
  });
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const uid = reduxUser?.uid;

  // Fetch user data from hook and update state
  useEffect(() => {
    const fetchUser = async () => {
      if (uid) {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/users/${uid}`
        );
        console.log("User data fetched:", res.data);
        setUser(res.data);
      } else {
        console.error("User not found.");
      }
    };
    fetchUser();
  }, [uid]);

  const religions = [
    "Christianity",
    "Buddhism",
    "Hinduism",
    "Islam",
    "Judaism",
    "Sikhism",
    "Other",
  ];

  const christianDenominations = [
    "Anglican",
    "Baptist",
    "Catholic",
    "Church of Jesus Christ of Latter-day Saints",
    "Eastern Orthodox",
    "Lutheran",
    "Methodist",
    "Non-denominational",
    "Pentecostal",
    "Presbyterian",
    "Seventh-day Adventist",
    "Quaker",
    "Wesleyan",
  ];

  // Update profile data from user object
  useEffect(() => {
    if (user) {
      setProfileData({
        displayName: user.displayName || "",
        email: user.email || "",
        testimony: user.testimony || "",
        religion: user.religion || "Christianity",
        denomination: user.denomination || "",
        latterDaySaint: user.latterDaySaint || false,
        emailNotifications: user.emailNotifications || true,
        pushNotifications: user.pushNotifications || true,
        theme: user.theme || "meek",
        darkMode: user.darkMode || false,
      });
    }
  }, [user]);

  // Clear messages after 3 seconds
  useEffect(() => {
    if (message || error) {
      const timer = setTimeout(() => {
        setMessage("");
        setError("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [message, error]); // runs when `message` or `error` changes

  const handleSaveChanges = () => {
    const updatedData = { uid: user.uid, ...profileData, ...privacySettings };
    dispatch(updateUserProfile(updatedData))
      .then(() => {
        setUserToCache({ ...user, ...profileData });
        setMessage("Profile updated successfully!");
      })
      .catch((err) => {
        setError("Failed to update profile.");
        console.error(err);
      });
  };

  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(storage, `profileImages/${user.uid}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          setError("Failed to upload profile image.");
          console.error("Error uploading file:", error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          dispatch(
            updateUserProfilePhoto({ uid: user.uid, photoURL: downloadURL })
          );
          setUserToCache({ ...user, photoURL: downloadURL });
          setMessage("Profile image updated successfully!");
        }
      );
    }
  };

  const handleDeleteAccount = () => {
    // Show warning and handle delete logic
    const confirmed = window.confirm(
      "Are you sure you want to delete your account? Anonymized data will not be deleted but won't be identifiable."
    );
    if (confirmed) {
      // Dispatch delete action
      // dispatch(deleteUserAccount(user.uid)); // TODO Implement deleteUserAccount action
      console.log("Account deleted");
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Navbar />
      <AppBar />
      <div className="mt-8 w-full flex flex-col md:flex-row gap-5 px-3 md:px-16 lg:px-28 text-[#161931]">
        <aside className="hidden py-4 md:w-1/3 lg:w-1/4 md:block">
          <div className="sticky flex flex-col gap-2 p-4 text-sm border-r border-indigo-100 top-12">
            <h2 className="pl-3 mb-4 text-2xl font-semibold">Settings</h2>
            <button
              onClick={() => setActiveTab("profile")}
              className={`flex items-center px-3 py-2.5 font-semibold ${
                activeTab === "profile"
                  ? "bg-indigo-200 text-indigo-900 border rounded-full"
                  : "hover:text-indigo-900 hover:border hover:rounded-full"
              }`}
            >
              Public Profile
            </button>
            <button
              onClick={() => setActiveTab("preferences")}
              className={`flex items-center px-3 py-2.5 font-semibold ${
                activeTab === "preferences"
                  ? "bg-indigo-200 text-indigo-900 border rounded-full"
                  : "hover:text-indigo-900 hover:border hover:rounded-full"
              }`}
            >
              Preferences
            </button>
            <button
              onClick={() => setActiveTab("privacy")}
              className={`flex items-center px-3 py-2.5 font-semibold ${
                activeTab === "privacy"
                  ? "bg-indigo-200 text-indigo-900 border rounded-full"
                  : "hover:text-indigo-900 hover:border hover:rounded-full"
              }`}
            >
              Privacy Settings
            </button>
            <button
              onClick={() => setActiveTab("support")}
              className={`flex items-center px-3 py-2.5 font-semibold ${
                activeTab === "support"
                  ? "bg-indigo-200 text-indigo-900 border rounded-full"
                  : "hover:text-indigo-900 hover:border hover:rounded-full"
              }`}
            >
              Get Help
            </button>
            <button
              onClick={() => setActiveTab("danger-zone")}
              className={`flex items-center px-3 py-2.5 font-semibold text-red-600 ${
                activeTab === "danger-zone"
                  ? "bg-red-200 text-red-600 border rounded-full"
                  : "hover:text-red-900 hover:border hover:rounded-full"
              }`}
            >
              Danger Zone
            </button>
          </div>
        </aside>

        <main className="w-full py-1 md:w-2/3 lg:w-3/4">
          <div className="p-4 bg-white shadow rounded-xl">
            {activeTab === "profile" && (
              <>
                <h3 className="mb-4 text-2xl font-bold">Public Profile</h3>

                {/* First Name */}
                <div className="mb-6">
                  <div className="relative inline-block mb-6 mr-0 md:mr-6 md:mb-0">
                    <img
                      src={user.photoURL || "https://via.placeholder.com/150"}
                      alt="User Avatar"
                      className="object-cover w-32 h-32 rounded-full"
                    />
                    <label
                      htmlFor="profile-image-upload"
                      className="absolute bottom-0 right-0 flex items-center justify-center w-10 h-10 text-white bg-blue-500 rounded-full cursor-pointer hover:bg-blue-600"
                    >
                      <FaEdit />
                      <input
                        id="profile-image-upload"
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={handleProfileImageChange}
                      />
                    </label>
                  </div>

                  <label
                    htmlFor="first_name"
                    className="block mt-4 mb-2 text-sm font-bold text-gray-700"
                  >
                    Name (as it will be displayed)
                  </label>
                  <div className="relative flex items-center w-full border rounded-3xl">
                    <FaUserAlt className="absolute w-4 h-4 text-gray-400 left-3" />
                    <input
                      type="text"
                      id="display-name"
                      value={profileData.displayName}
                      onChange={(e) =>
                        setProfileData({
                          ...profileData,
                          displayName: e.target.value,
                        })
                      }
                      className="w-full py-2 pl-10 bg-meek-light rounded-3xl focus:ring focus:ring-indigo-500"
                      placeholder="Enter your first name"
                    />
                  </div>
                </div>

                {/* Email */}
                <div className="mb-6">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-bold text-gray-700"
                  >
                    Email
                  </label>
                  <div className="relative flex items-center w-full border rounded-3xl">
                    <MdEmail className="absolute w-5 h-5 text-gray-400 left-3" />
                    <input
                      type="email"
                      id="email"
                      value={profileData.email}
                      onChange={(e) =>
                        setProfileData({
                          ...profileData,
                          email: e.target.value,
                        })
                      }
                      className="w-full py-2 pl-10 bg-meek-light rounded-3xl focus:ring focus:ring-indigo-500"
                      placeholder="Enter your email"
                    />
                  </div>
                </div>

                {/* Religion Dropdown */}
                <div className="mb-6">
                  <label
                    htmlFor="religion"
                    className="block mb-2 text-sm font-bold text-gray-700"
                  >
                    Religion
                  </label>
                  <select
                    id="religion"
                    value={profileData.religion || "Christianity"}
                    onChange={(e) =>
                      setProfileData({
                        ...profileData,
                        religion: e.target.value,
                      })
                    }
                    className="w-full px-4 py-2 border bg-meek-light rounded-3xl focus:ring focus:ring-indigo-500"
                  >
                    {religions.sort().map((religion, idx) => (
                      <option key={idx} value={religion}>
                        {religion}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Denomination Dropdown (only shows for Christianity) */}
                {profileData.religion === "Christianity" && (
                  <div className="mb-6">
                    <label
                      htmlFor="denomination"
                      className="block mb-2 text-sm font-bold text-gray-700"
                    >
                      Denomination
                    </label>
                    <select
                      id="denomination"
                      value={profileData.denomination || ""}
                      onChange={(e) =>
                        setProfileData({
                          ...profileData,
                          denomination: e.target.value,
                        })
                      }
                      className="w-full px-4 py-2 border bg-meek-light rounded-3xl focus:ring focus:ring-indigo-500"
                    >
                      <option value="">Select a denomination</option>
                      {christianDenominations.sort().map((denom, idx) => (
                        <option key={idx} value={denom}>
                          {denom}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {/* Member of the Church of Jesus Christ Toggle */}
                <div className="mb-6">
                  <label
                    htmlFor="church_content"
                    className="block mb-2 text-sm font-bold text-gray-700"
                  >
                    The Church of Jesus Christ of Latter-day Saints Membership
                  </label>
                  <div className="flex items-center text-gray-700">
                    <input
                      type="checkbox"
                      id="lds_content"
                      checked={profileData.latterDaySaint}
                      onChange={(e) =>
                        setProfileData({
                          ...profileData,
                          latterDaySaint: e.target.checked,
                        })
                      }
                      className="mx-6"
                    />
                    I am a member of the Church of Jesus Christ of Latter-day
                    Saints or want to see Church content while using
                    Discipleship.
                  </div>
                </div>

                {/* Save Button */}
                <div className="flex justify-end">
                  <button
                    onClick={handleSaveChanges}
                    className="w-full px-6 py-2 font-bold text-white sm:w-auto bg-meek-dark rounded-3xl hover:bg-indigo-900"
                  >
                    Save Changes
                  </button>
                </div>
              </>
            )}

            {activeTab === "preferences" && (
              <>
                <h3 className="mb-4 text-2xl font-bold">
                  User Preferences (Coming Soon!)
                </h3>

                {/* Dark Mode Selector */}
                <div className="mb-6">
                  <label
                    htmlFor="darkMode"
                    className="block mb-2 text-sm font-bold text-gray-700"
                  >
                    Theme
                  </label>
                  <select
                    id="darkMode"
                    className="w-full px-4 py-2 border bg-meek-light rounded-3xl focus:ring focus:ring-indigo-500"
                    value={profileData.darkMode}
                  >
                    <option value="light">Light</option>
                    <option value="dark">Dark</option>
                    <option value="System">Faithful</option>
                  </select>
                </div>

                {/* Show Theme Selector */}
                <div className="mb-6">
                  <label
                    htmlFor="theme"
                    className="block mb-2 text-sm font-bold text-gray-700"
                  >
                    Theme
                  </label>
                  <select
                    id="theme"
                    className="w-full px-4 py-2 border bg-meek-light rounded-3xl focus:ring focus:ring-indigo-500"
                    value={profileData.theme}
                  >
                    <option value="faithful">Faithful</option>
                    <option value="loving">Loving</option>
                    <option value="meek">Meek</option>

                    {/* Add more themes here */}
                  </select>
                </div>

                {/* Show Email Notifications */}
                <div className="mb-6">
                  <label
                    htmlFor="email_notifications"
                    className="block mb-2 text-sm font-bold text-gray-700"
                  >
                    Email Notifications
                  </label>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="email_notifications"
                      className="mr-2"
                      checked={profileData.emailNotifications}
                    />
                    <span className="text-gray-700">
                      Enable email notifications
                    </span>
                  </div>
                </div>

                {/* Save Button */}
                <div className="flex justify-end">
                  <button
                    onClick={handleSaveChanges}
                    className="w-full px-6 py-2 font-bold text-white sm:w-auto bg-meek-dark rounded-3xl hover:bg-indigo-900"
                  >
                    Save Privacy Settings
                  </button>
                </div>
              </>
            )}

            {activeTab === "privacy" && (
              <>
                <h3 className="mb-4 text-2xl font-bold">
                  Privacy Settings (Coming Soon!)
                </h3>

                {/* Show Email Toggle */}
                <div className="mb-6">
                  <label
                    htmlFor="show_email"
                    className="block mb-2 text-sm font-bold text-gray-700"
                  >
                    Show Email to Public
                  </label>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="show_email"
                      checked={privacySettings.showEmail}
                      onChange={(e) =>
                        setPrivacySettings({
                          ...privacySettings,
                          showEmail: e.target.checked,
                        })
                      }
                      className="mr-2"
                    />
                    <span className="text-gray-700">Make email visible</span>
                  </div>
                </div>

                {/* Show Profile Toggle */}
                <div className="mb-6">
                  <label
                    htmlFor="show_profile"
                    className="block mb-2 text-sm font-bold text-gray-700"
                  >
                    Show Profile to Public
                  </label>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="show_profile"
                      checked={privacySettings.showProfileToPublic}
                      onChange={(e) =>
                        setPrivacySettings({
                          ...privacySettings,
                          showProfileToPublic: e.target.checked,
                        })
                      }
                      className="mr-2"
                    />
                    <span className="text-gray-700">Make profile visible</span>
                  </div>
                </div>

                {/* Save Button */}
                <div className="flex justify-end">
                  <button
                    onClick={handleSaveChanges}
                    className="w-full px-6 py-2 font-bold text-white sm:w-auto bg-meek-dark rounded-3xl hover:bg-indigo-900"
                  >
                    Save Privacy Settings
                  </button>
                </div>
              </>
            )}

            {activeTab === "danger-zone" && (
              <>
                <h3 className="mb-4 text-2xl font-bold">
                  Danger Zone (Coming Soon! You can't leave yet!)
                </h3>

                {/* Reset All Data */}
                <div className="mb-6">
                  <button className="p-2 mr-2 border rounded-lg bg-danger hover:bg-transparent hover:text-danger">
                    Reset All Data
                  </button>
                </div>

                {/* Delete Account */}
                <div className="mb-6">
                  <button
                    onClick={handleDeleteAccount}
                    className="p-2 border rounded-lg bg-danger hover:bg-transparent hover:text-danger"
                  >
                    Delete Account
                  </button>
                </div>
              </>
            )}

            {activeTab === "support" && (
              <>
                <h3 className="mb-4 text-2xl font-bold">
                  Support (Not Finished! Also Coming Soon!)
                </h3>

                <p className="mb-4">
                  If you need help with anything, please email us at{" "}
                  <a
                    href="mailto:support@pathtochrist.life" // Update email address
                    className="text-indigo-500"
                  ></a>
                </p>

                <p className="mb-4">
                  For bug reports or feature requests, please visit our{" "}
                  <a
                    href="/canny" // Update Canny board URL
                    className="text-indigo-500"
                  >
                    Canny board
                  </a>
                  .
                </p>

                <p className="mb-4">
                  If you have any other questions, feel free to reach out to us
                  on social media.
                </p>

                <p className="mb-4">
                  Follow us on{" "}
                  <a
                    href="https://instagram.com/pathtochrist.life" // Update Instagram URL
                    className="text-indigo-500"
                  >
                    Instagram
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://twitter.com/pathtochristlife" // Update Twitter URL
                    className="text-indigo-500"
                  >
                    Twitter
                  </a>
                  .
                </p>

                {/* Send Message */}
                <div className="m-6">
                  <label
                    htmlFor="message"
                    className="block mb-2 text-sm font-bold text-gray-700"
                  >
                    Send a Message
                  </label>
                  <div className="relative flex justify-start w-full border rounded-3xl">
                    <input
                      type="text"
                      id="message"
                      className="justify-start w-full h-64 py-2 pl-10 bg-meek-light rounded-3xl focus:ring focus:ring-indigo-500"
                      placeholder="Send us a message"
                    />
                  </div>
                </div>
              </>
            )}

            {/* Message and Error Handling */}
            {message && (
              <p className="mt-4 text-center text-green-500">{message}</p>
            )}
            {error && <p className="mt-4 text-center text-red-500">{error}</p>}
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default SettingsPage;
